// frontend/src/Dashboard.jsx
import React, { useState, useEffect } from 'react';
import { Layout, Row, Col, Card, Button, Space, message, Modal, Form, Input, Select, Dropdown, Tabs, Table, Switch, Collapse, Menu } from 'antd';
import { DownOutlined, LinkOutlined, EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { useNavigate, Link } from 'react-router-dom';
import RichTextEditor from './RichTextEditor';
import { useForm } from 'antd/es/form/Form';
import axios from 'axios';
import './Dashboard.css';
import logo from './logo-f.png';
import Quill from 'quill';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';


const { Header, Content } = Layout;

const Dashboard = () => {
  const [viewMode, setViewMode] = useState('table');
  const [products, setProducts] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isSettingsModalVisible, setIsSettingsModalVisible] = useState(false);
  const [websiteStatuses, setWebsiteStatuses] = useState({}); // Initialize with an empty object
  const [lastCheckedTimes, setLastCheckedTimes] = useState({}); // Initialize with empty object


  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [newUserForm] = Form.useForm();
  const [newProductForm] = Form.useForm();
  const navigate = useNavigate();
  const { Panel } = Collapse;

  async function logClickEvent(buttonName) {
    try {
      const token = localStorage.getItem('token');
      await axios.post('https://backend.capitall-os.com/activity_log', {
        action: 'click',
        details: buttonName
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });
    } catch (error) {
      console.error('Error logging click event:', error);
    }
  }

  const fetchProducts = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('https://capitall-os.com/backend/products', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setProducts(response.data);
      setIsAdmin(localStorage.getItem('role') === 'admin');
    } catch (error) {
      message.error('Failed to fetch products');

      if (error.response.status === 401) {
        navigate('/');
      }
    }
  };

  useEffect(() => {
    const fetchProductsAndStatuses = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('https://capitall-os.com/backend/products', {
          headers: { Authorization: `Bearer ${token}` }
        });

        const fetchedProducts = response.data;
        setProducts(fetchedProducts);
        setIsAdmin(localStorage.getItem('role') === 'admin');

        // Fetch website statuses
        const newStatuses = {};
        const newCheckedTimes = {};
        for (const product of fetchedProducts) {
          try {
            const statusResponse = await axios.head("https://capitall-os.com/cors/" + product.demo_url);
            console.log(product.demo_url + '==' + statusResponse.status);
            newStatuses[product.id] = statusResponse.status === 200 ? 'Online' : 'Offline';
            newCheckedTimes[product.id] = new Date().toLocaleTimeString();
          } catch (error) {
            newStatuses[product.id] = 'Offline';
            newCheckedTimes[product.id] = new Date().toLocaleTimeString();
          }
        }
        setWebsiteStatuses(newStatuses);
        setLastCheckedTimes(newCheckedTimes);
      } catch (error) {
        message.error('Failed to fetch products');
        if (error.response && error.response.status === 403) { // Check if error.response exists before accessing its properties
          navigate('/');
        }
      }
    };

    fetchProductsAndStatuses(); // Initial fetch
    const intervalId = setInterval(fetchProductsAndStatuses, 30000); // Re-fetch every 30 seconds

    return () => clearInterval(intervalId);
  }, [isSettingsModalVisible, isEditModalVisible]);



  // Settings Modal Functions
  const showSettingsModal = () => {
    setIsSettingsModalVisible(true);
  };

  const handleCancelSettings = () => {
    setIsSettingsModalVisible(false);
    newUserForm.resetFields();
    newProductForm.resetFields();
  };



  // User Creation
  const handleCreateUser = async (values) => {
    try {
      const token = localStorage.getItem('token');
      await axios.post('https://capitall-os.com/backend/auth/users', values, {
        headers: { Authorization: `Bearer ${token}` }
      });
      message.success('User created successfully');
      setIsSettingsModalVisible(false); // Hide the modal after creation
      newUserForm.resetFields();
      // (Optionally) Refresh the user list
    } catch (error) {
      message.error('Failed to create user');
    }
  };

  // const columns = [
  //   { title: 'Name', dataIndex: 'name', key: 'name' },
  //   { title: 'Status', dataIndex: 'status', key: 'status' },
  //   { title: 'Notes', dataIndex: 'notes', key: 'notes', width: 500 },
  //   {
  //     title: 'Action',
  //     key: 'action',
  //     render: (_, record) => (
  //       <Space size="middle">
  //         <Button onClick={() => window.open(record.demo_url, '_blank')} icon={<LinkOutlined />}>Open</Button>
  //         {isAdmin && (
  //           <>
  //             <Button icon={<EditOutlined />} onClick={() => handleEditProduct(record)}>Edit</Button>
  //             <Button danger icon={<DeleteOutlined />} onClick={() => handleDeleteProduct(record.id)}>Delete</Button>
  //           </>
  //         )}
  //       </Space>
  //     ),
  //   },
  // ];

  const columns = [
    { title: 'Name', dataIndex: 'name', key: 'name' },
    // { title: 'Status', dataIndex: 'status', key: 'status' },
    {
      title: 'Website Status',
      dataIndex: 'id', // Using product id to access website status from state
      key: 'websiteStatus',
      render: (id) => (
        <div className="status-container">
          <span className={`status-badge ${websiteStatuses[id]?.toLowerCase() || ''}`}>
            {websiteStatuses[id] || 'Checking...'}
          </span>
          <span className="last-checked">
            Last checked: {lastCheckedTimes[id] || ''}
          </span>
        </div>
      ),
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
      key: 'notes',
      width: 500,
      render: (_, record) => (
        <Collapse ghost>
          <Panel header="View Notes" key={record.id}>
            <div dangerouslySetInnerHTML={{ __html: record.notes }} />
          </Panel>
        </Collapse>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={() => {
              logClickEvent('Open Product');
              window.open(record.demo_url, '_blank');
            }}
            icon={<LinkOutlined />}
          >
            Open
          </Button>
          {isAdmin && (
            <>
              <Button icon={<EditOutlined />} onClick={() => handleEditProduct(record)}>
                Edit
              </Button>
              <Button
                danger
                icon={<DeleteOutlined />}
                onClick={() => handleDeleteProduct(record.id)}
              >
                Delete
              </Button>
            </>
          )}
        </Space>
      ),
    },
  ];


  // Product Creation
  const handleCreateProduct = async (values) => {
    try {
      const token = localStorage.getItem('token');
      await axios.post('https://capitall-os.com/backend/products', values, {
        headers: { Authorization: `Bearer ${token}` }
      });
      message.success('Product created successfully');
      setIsSettingsModalVisible(false); // Hide the modal after creation
      newProductForm.resetFields();
      fetchProducts();
    } catch (error) {
      message.error('Failed to create product');
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    navigate('/'); // Redirect to login after logout
  };

  // // Header Menu
  // const menuItems = [
  //   {
  //     key: 'settings', // Unique key for the menu item
  //     label: 'Settings',
  //     onClick: showSettingsModal
  //   }
  // ];

  // const handleEditProduct = (product) => {
  //   setSelectedProduct(product);
  //   setIsEditModalVisible(true);
  // };

  const handleEditProduct = (product) => {
    setSelectedProduct(product);
    setIsEditModalVisible(true);


    // No need to convert here, as ReactQuill works with HTML
    newProductForm.setFieldsValue({
      name: product.name,
      status: product.status,
      demo_url: product.demo_url,
      notes: product.notes
    });
  };

  const handleDeleteProduct = async (productId) => {
    try {
      const token = localStorage.getItem('token');
      await axios.delete(`https://capitall-os.com/backend/products/${productId}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      message.success('Product deleted successfully');
      // Refresh the product list
      fetchProducts();
    } catch (error) {
      message.error('Failed to delete product');
    }
  };
  const handleUpdateProduct = async (values) => {
    try {
      const token = localStorage.getItem('token');

      await axios.put(`https://capitall-os.com/backend/products/${selectedProduct.id}`, {
        ...values,
        // Notes are already in the format ReactQuill expects
        // notes: values.notes
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });

      message.success("Product updated successfully");
      setIsEditModalVisible(false);
      fetchProducts(); // Refresh product list
    } catch (error) {
      message.error("Failed to update product");
    }
  };

  // Toggle View Mode
  const handleViewModeChange = (checked) => {
    setViewMode(checked ? 'table' : 'card');
  };

  // frontend/src/Dashboard.jsx (return statement)
  return (
    <Layout className="layout">
      <Header className="header">
        <div className="logo-container">
          <img src={logo} alt="Capital OS Logo" className="logo-image" />

        </div>

        <Menu theme="light" mode="horizontal" defaultSelectedKeys={['2']}>
          {/* All menu items are now visible to everyone */}
          <Menu.Item key="2">
            <Link to="/dashboard">Dashboard</Link>
          </Menu.Item>
          {/* <Menu.Item key="3">
            <Link to="/goals">Goals</Link>
          </Menu.Item> */}

          {/* <Menu.Item key="1">
            <Switch
              defaultChecked
              checkedChildren="Table"
              unCheckedChildren="Card"
              onChange={handleViewModeChange}
              style={{ marginRight: '16px' }}
            />
          </Menu.Item> */}

          {/* Logout button is still conditional for logged-in users */}
          {localStorage.getItem('token') && ( // Check for token to determine login status
            <Menu.Item key="4">
              <Button type="link" onClick={handleLogout}>Logout</Button>
            </Menu.Item>
          )}
        </Menu>
      </Header>

      <Content style={{ padding: '24px' }}>
        <div className="site-layout-content">
          <Button type="primary" icon={<PlusOutlined />} onClick={showSettingsModal} style={{ marginBottom: 16 }}>
            Add New Product
          </Button>
          {viewMode === 'card' ? (
            <Row gutter={[16, 16]}>
              {products.map((product) => (
                <Col xs={24} sm={12} md={8} lg={4} key={product.id}>
                  <Card bordered={false} className="product-card">
                    <h5 className="product-title">{product.name}</h5>
                    {/* <p>
                      <b>Environment:</b> {product.status}
                    </p> */}
                    <div className="status-container">
                      <span
                        className={`status-badge ${websiteStatuses[product.id]?.toLowerCase() || "checking"
                          }`}
                      >
                        {websiteStatuses[product.id] || "Checking..."}
                      </span>
                      <span className="last-checked">
                        Last Checked: {lastCheckedTimes[product.id] || "Not Checked"}
                      </span>
                    </div>
                    {/* {product.notes ? (
                      <div dangerouslySetInnerHTML={{ __html: product.notes }}></div>
                    ) : (
                      <p>No description available.</p>
                    )} */}
                    <div className="card-actions">
                      <Button
                        className="btn btn-open"
                        onClick={() => {
                          logClickEvent("Open Product");
                          window.open(product.demo_url, "_blank");
                        }}
                      >
                        <LinkOutlined /> Open
                      </Button>
                      {isAdmin && (
                        <>
                          <Button
                            className="btn btn-edit"
                            onClick={() => handleEditProduct(product)}
                          >
                            <EditOutlined /> Edit
                          </Button>
                          <Button
                            danger
                            className="btn btn-delete"
                            onClick={() => handleDeleteProduct(product.id)}
                          >
                            <DeleteOutlined /> Delete
                          </Button>
                        </>
                      )}
                    </div>
                  </Card>
                </Col>
              ))}
            </Row>
          ) : (
            <Table dataSource={products} columns={columns} />
          )}
          <Modal
            title="Edit Product"
            open={isEditModalVisible}
            onCancel={() => {
              setIsEditModalVisible(false);
              newProductForm.resetFields();

            }}
            footer={null}
          >
            {selectedProduct && (
              <Form
                form={newProductForm}
                initialValues={{
                  name: selectedProduct.name,
                  status: selectedProduct.status,
                  demo_url: selectedProduct.demo_url,
                  notes: selectedProduct.notes ? selectedProduct.notes : [{ type: 'paragraph', children: [{ text: '' }] }]
                }}
                onFinish={handleUpdateProduct}
                style={{ marginBottom: '24px' }}
              >
                {/* Form fields for editing product name, status, and demo URL */}
                <Form.Item name="name" label="Product Name" rules={[{ required: true, message: 'Please input product name!' }]}>
                  <Input />
                </Form.Item>
                <Form.Item name="status" label="Status" rules={[{ required: true, message: 'Please input product status!' }]}>
                  <Input />
                </Form.Item>
                <Form.Item
                  name="demo_url"
                  label="Demo URL"
                  rules={[
                    { required: true, message: 'Please input demo URL!' },
                    { type: 'url', message: 'Please enter a valid URL' },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item name="notes" label="Notes">
                  <RichTextEditor
                    onChange={(value) => newProductForm.setFieldsValue({ notes: value })}
                  />
                </Form.Item>

                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Update Product
                  </Button>
                </Form.Item>
              </Form>
            )}
          </Modal>
          <Modal
            title="Settings"
            open={isSettingsModalVisible}
            onCancel={handleCancelSettings}
            footer={null} // We'll add buttons in the form
          >
            <Tabs defaultActiveKey="1">
              <Tabs.TabPane tab="Create New User" key="1">
                <Form form={newUserForm} onFinish={handleCreateUser} style={{ marginBottom: '24px' }}>
                  <Form.Item
                    name="username"
                    label="Username"
                    rules={[{ required: true, message: 'Please input username!' }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    label="Password"
                    rules={[{ required: true, message: 'Please input password!' }]}
                  >
                    <Input.Password />
                  </Form.Item>
                  <Form.Item
                    name="role"
                    label="Role"
                    rules={[{ required: true, message: 'Please select a role!' }]}
                  >
                    <Select>
                      <Select.Option value="user">User</Select.Option>
                      <Select.Option value="admin">Admin</Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">Create User</Button>
                  </Form.Item>
                </Form>
              </Tabs.TabPane>

              <Tabs.TabPane tab="Add New Product" key="2">
                <Form form={newProductForm} onFinish={handleCreateProduct}>
                  <Form.Item
                    name="name"
                    label="Product Name"
                    rules={[{ required: true, message: 'Please input product name!' }]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="status"
                    label="Status"
                    rules={[{ required: true, message: 'Please input product status!' }]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="demo_url"
                    label="Demo URL"
                    rules={[
                      { required: true, message: 'Please input demo URL!' },
                      { type: 'url', message: 'Please enter a valid URL' },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="notes"
                    label="Notes"
                    rules={[
                      {
                        validator: async (_, value) => {
                          // Check if the editor content is empty
                          if (
                            !value ||
                            value.length === 0 ||
                            (value.length === 1 &&
                              value[0].children &&
                              value[0].children[0].text === '') // Stricter check
                          ) {
                            return Promise.reject('Please enter notes for the product');
                          }
                        },
                      },
                    ]}
                  >
                    <RichTextEditor
                      value={[{ type: 'paragraph', children: [{ text: '' }] }]}
                      onChange={(value) => newProductForm.setFieldsValue({ notes: value })}
                    />
                  </Form.Item>

                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Add Product
                    </Button>
                  </Form.Item>
                </Form>
              </Tabs.TabPane>
            </Tabs>
          </Modal>
        </div>
      </Content>
    </Layout>
  );


};

export default Dashboard;
